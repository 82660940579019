body {
  -webkit-print-color-adjust: exact !important;
  background-color: white !important;
}
@page {
  /* size: 287mm 209mm; */
  size: 287mm 210mm landscape !important;
  /* margin: 1cm 1cm; */
}
/* page[size="A4"] {
  background: white;
  width: 21cm;
  height: 27cm;
  display: block;
  margin: 5mm auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
} */
/* @media print {
  @page {
    size: 287mm 210mm landscape;
    margin: 1cm 1cm;
  }
} */
